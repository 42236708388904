import './action_requests'
import './locations'
import './cards'
import './shipments'
import './escorts'


import './home'

import Search from "./search";

document.addEventListener("DOMContentLoaded", (e) => {
    (document.querySelectorAll("a[data-language]") as NodeListOf<HTMLElement>).forEach((elem) => {
        const newLocale = elem.getAttribute("data-language");
        // @ts-ignore
        const url = document.location.href.replace(currentLocale, newLocale)
        elem.setAttribute("href", url)
    });

    new Search();
})